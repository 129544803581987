import React from 'react';
import styled from 'styled-components';
import Layout from 'components/Layout';
import { graphql, Link } from 'gatsby';
import { MdChevronLeft } from 'react-icons/md';
import Seo from 'components/SEO';
import { Section, Button } from 'ui';
import PoisMap from 'components/PoisMap';
import Header from 'components/Header';
import headerPro from 'images/headerPro.svg';

const BackButton = styled(Button)`
  position: relative;
  width: 1.5em;
  height: 1.5em;
  min-width: 0;
  min-height: 0;
  padding: 0;
  margin-right: ${({ theme }) => theme.spacing()};

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    margin-left: 0;
  }
`;

const BackButtonLink = ({ to }) => (
  <BackButton as={Link} variant="primary" to={to}>
    <MdChevronLeft size={32} />
  </BackButton>
);

const MapPage = ({ data }) => {
  const categories = data?.allPrismicPoiCategory?.edges ?? [];

  const pois = data?.allPrismicPoi?.edges ?? [];

  return (
    <Layout variant="pro">
      <Seo title="Carte" />
      <Header
        title="Accès professionnels de santé"
        icon={headerPro}
        variant="withLogo"
        app="pro"
      />

      <Section paddingSize="none">
        <PoisMap
          topChildren={<BackButtonLink to="/pro" />}
          categories={categories}
          pois={pois}
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query ProMapQuery {
    allPrismicPoiCategory(filter: { data: { target: { eq: "pro" } } }) {
      edges {
        node {
          data {
            target
            name {
              text
            }
            icon {
              alt
              url
            }
            target
            description {
              richText
            }
          }
          uid
        }
      }
    }
    allPrismicPoi(limit: 1000) {
      edges {
        node {
          uid
          data {
            address {
              text
            }
            coordinates {
              latitude
              longitude
            }
            description {
              richText
            }
            name {
              text
            }
            category {
              document {
                ... on PrismicPoiCategory {
                  id
                  uid
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MapPage;
